import React from 'react'
import Layout from '../../components/layout'
import SEO from '../../components/seo'
import { Row, Col, Button, WhatNext, Video } from '../../components/global'
import Image from '../../components/image'
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion'
import 'react-accessible-accordion/dist/fancy-example.css'

const Shortcuts = () => (
  <Layout>
    <SEO title="Vehicle Electrical Troubleshooting Shortcuts" />
    <article itemScope itemType="http://schema.org/Product" className="container container-last">
      <h1 itemProp="name">
        Vehicle Electrical Troubleshooting <em>Shortcuts</em>
      </h1>
      <meta itemprop="image" src="https://www.veejer.com/Shortcuts-Cover.jpg" />
      <p className="boost">Become the "go-to" electrical tech in your shop.</p>
      <hr />

      <Row>
        <Col width="35%" align="left">
          <section className="book-container">
            <div className="book-container-inner">
              <h1>
                <span>Vehicle Electrical Troubleshooting</span> <em>SHORTCUTS</em>
              </h1>
              <ul>
                <li>Batteries</li>
                <li>Cranking Circuits</li>
                <li>Charging Circuits</li>
                <li>Reading Schematics</li>
              </ul>
              <p className="book-container-author">
                by Vince Fischelli <br /> Veejer Enterprises
              </p>
              <Image file="logo-sm.png" />
            </div>
          </section>
        </Col>
        <Col width="63%" align="left">
          <p>
            Packed full of how-to-do electrical troubleshooting procedures that are effective in
            finding electrical problems and save valuable testing and troubleshooting time which
            will increase your profits. Prevents changing unnecessary parts. No more guessing about
            what's causing an electrical problem. Find out quickly and fix the problem correctly the
            first time.
          </p>
          <p>
            <Button
              href="http://www.1shoppingcart.com/SecureCart/SecureCart.aspx?mid=2169DBD4-F088-4CA4-A011-56C1593411AC&pid=293d5ad32440417abd9004d5cf464d7d"
              text="Buy in print $98.00 + shipping"
              type="secondary"
            />{' '}
            <span className="meta button-meta">
              The complete Printed Volume with 250 pages, spiral bound with 170 illustrations and
              schematics.
            </span>
          </p>
          <p>
            <Button
              href="http://www.1shoppingcart.com/SecureCart/SecureCart.aspx?mid=2169DBD4-F088-4CA4-A011-56C1593411AC&pid=a9ccdb01c0104e80881b9e06472e600c"
              text="Buy as PDF $98.00"
            />
            <span className="meta button-meta">
              No shipping charge. PDF is read-only (no printing available). Complete volume of
              <em>SHORTCUTS</em> Sections 1-7 in .pdf format is emailed to you the next work day.
              You will receive the complete book in a .pdf (read only) file by email.
            </span>
          </p>
          <div itemProp="offers" itemType="http://schema.org/Offer" itemScope>
            <link itemProp="url" href="https://www.veejer.com/products/shortcuts" />
            <meta itemProp="availability" content="https://schema.org/InStock" />
            <meta itemProp="priceCurrency" content="USD" />
            <meta itemProp="itemCondition" content="https://schema.org/NewCondition" />
            <meta itemProp="price" content="98.00" />
            <div itemProp="seller" itemType="http://schema.org/Organization" itemScope>
              <meta itemProp="name" content="Veejer Enterprises, Inc." />
            </div>
          </div>
        </Col>
      </Row>
      <hr />
      <p className="boost">
        See Electrical <em>SHORTCUTS</em> in action with this youtube series!
      </p>
      <Row>
        <Col width="32%">
          <Video
            title="TW #10 - Electrical Testing Shortcuts with Vince Fischelli: Part One (Battery Testing)"
            src="https://www.youtube.com/embed/7UAVc4Z2lhY"
          />
          <p>
            TW #10 - Electrical Testing Shortcuts with Vince Fischelli: Part One (Battery Testing)
          </p>
        </Col>
        <Col width="32%">
          <Video
            title="ITW #11 - Electrical Testing Shortcuts with Vince Fischelli: Part Two (Starting Systems)"
            src="https://www.youtube.com/embed/EDciaypL8Vw"
          />
          <p>
            ITW #11 - Electrical Testing Shortcuts with Vince Fischelli: Part Two (Starting Systems)
          </p>
        </Col>
        <Col width="32%">
          <Video
            title="ITW #12 - Electrical Testing Shortcuts with Vince Fischelli: Part 3 (Charging Systems)"
            src="https://www.youtube.com/embed/4EFzX3SN6ck"
          />
          <p>
            ITW #12 - Electrical Testing Shortcuts with Vince Fischelli: Part 3 (Charging Systems)
          </p>
        </Col>
      </Row>
      <hr />
      <p className="boost">
        Learn to find the cause of an electrical failure while the circuit is still on-the-vehicle.
      </p>
      <p>
        Topics of Seven Sections in <em>SHORTCUTS</em>
      </p>
      <Accordion allowZeroExpanded={true}>
        <AccordionItem>
          <AccordionItemHeading>
            <AccordionItemButton>Section 1: Essential Electrical Principles</AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <p>26 Pages - 11 Illustrations</p>
            <p>
              The first section is an introduction to essential electrical principles for those who
              have never studied electrical principles before or would like a brief review. It
              covers electrical subjects in a way that is practical for vehicle service technicians.
            </p>
            <p>Topics in Section 1:</p>
            <ul>
              <li>Introduction to electricity</li>
              <li>Flashlight operates on electrical principles</li>
              <li>The load in a circuit</li>
              <li>Circuit ON/OFF Switch</li>
              <li>Electrical terminology when a flashlight circuit is OFF</li>
              <li>When a flashlight circuit is turned ON and electron current flows</li>
              <li>In-rush electron current</li>
              <li>Conventional theory of current</li>
              <li>Important electrical terminology when a flashlight is OFF</li>
              <li>It's electron current that gets the work done</li>
              <li>Electrical principles to remember</li>
              <li>Electrical property of resistance - Impact of resistance in a circuit</li>
              <li>The problem caused by corrosion</li>
              <li>The relationship between voltage and electron current</li>
              <li>The relationship between resistance and current</li>
              <li>The relationship between voltage, current and resistance</li>
              <li>Ohm's Law</li>
              <li>Introduction to schematic diagrams</li>
              <li>Vehicle battery schematic symbols</li>
              <li>"Schemation" diagrams - Voltage side of vehicle primary electrical system</li>
              <li>Voltage side B+; Ground side of vehicle voltage source B-; Dual ground system</li>
              <li>Vehicle Ground Circuits</li>
            </ul>
          </AccordionItemPanel>
        </AccordionItem>
        <AccordionItem>
          <AccordionItemHeading>
            <AccordionItemButton>Section 2: How to use a DMM and Current Clamp</AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <p>34 Pages - 44 Illustrations</p>
            <p>
              What a DMM can do and can't do and common mistakes using a DMM or a current clamp!
              Section 2 shows you how to use a DMM and current clamp!
            </p>
            <p>Topics in Section 2:</p>
            <ul>
              <li>Introduction to DMMs</li>
              <li>Two types of DMMs in use</li>
              <li>Caution reading auto ranging DMM</li>
              <li>Converting between volts and millivolts</li>
              <li>DMM voltage ranges</li>
              <li>Tips using a voltmeter</li>
              <li>DMM Current ranges</li>
              <li>Proper use of 20A range</li>
              <li>Tips using an ammeter</li>
              <li>Ohmmeters in general</li>
              <li>Human body resistance affects ohmmeter readings - Errors reading ohmmeters</li>
              <li>Practice testing resistance with ohmmeters</li>
              <li>Tips using an ohmmeter</li>
              <li>Continuity test</li>
              <li>Reading the continuity display</li>
              <li>Conductivity vs. Continuity</li>
              <li>Diode testing</li>
              <li>Semiconductor diodes</li>
              <li>How to use the diode test feature</li>
              <li>Tips using diode test</li>
              <li>Current clamps</li>
              <li>Current clamp set-up</li>
              <li>How a current clamp works</li>
              <li>Tips using a current clamp</li>
              <li>Frequency measurement</li>
            </ul>
          </AccordionItemPanel>
        </AccordionItem>
        <AccordionItem>
          <AccordionItemHeading>
            <AccordionItemButton>Section 3: How Circuits Really Work</AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <p>35 Pages - 40 Illustrations</p>
            <p>
              How can you troubleshoot circuits if you don't know how circuits work? Section 3 shows
              you how circuits work &amp; how circuits fail!
            </p>
            <p>Topics in Section 3:</p>
            <ul>
              <li>Why study electrical circuit principles?</li>
              <li>Water hose in a series connection</li>
              <li>Hose water current</li>
              <li>Electrical series circuit</li>
              <li>The law of (electron) current in a series circuit</li>
              <li>Impact of resistance R1</li>
              <li>Current takes the path of least resistance</li>
              <li>Measuring electron current in a series circuit</li>
              <li>Voltage in a series circuit</li>
              <li>How much should a Vd (voltage drop) be?</li>
              <li>Vd of components in a circuit - Law of voltage in a series circuit</li>
              <li>The voltage drop of the voltage side</li>
              <li>The voltage drop of the ground side</li>
              <li>Law of resistance in a series circuit</li>
              <li>Load resistance</li>
              <li>Starter Kit H-111A troubleshooting training</li>
              <li>Two water hoses in parallel</li>
              <li>Electrical parallel circuit</li>
              <li>
                Law of current in parallel circuits - Current takes the path of least resistance
              </li>
              <li>Measuring total electron current in a parallel circuit</li>
              <li>Measuring individual parallel branch electron current</li>
              <li>Law of voltage in parallel circuits</li>
              <li>Measuring voltage inside a branch</li>
              <li>Law of resistance in parallel circuits</li>
              <li>Example of resistors in parallel</li>
              <li>Compound circuit</li>
              <li>Voltage measurement techniques</li>
              <li>Measuring B+</li>
              <li>Measuring Vd of the voltage side</li>
              <li>Measuring voltage drop of the ground side</li>
              <li>Putting it all together</li>
              <li>H-113 Troubleshooting DC Motor Circuits Troubleshooting Trainer.</li>
            </ul>
          </AccordionItemPanel>
        </AccordionItem>
        <AccordionItem>
          <AccordionItemHeading>
            <AccordionItemButton>
              Section 4: Troubleshooting techniques of Battery Circuits
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <p>34 Pages - 18 Illustrations</p>
            <p>
              Can you verify by testing if a battery is good, marginal or bad in about 30 seconds?
              Can you test a battery to be sure it won't burn up a generator? Can you test a battery
              for proper charging in less than 5 minutes?) Section 4 shows you how!
            </p>
            <p>Topics in Section 4:</p>
            <ul>
              <li>Introduction to batteries</li>
              <li>
                Understand how the generator and battery work together. What happens to battery
                voltage (what does battery voltage tell you about a battery?)
              </li>
              <li>(What does battery current tell you about a battery?</li>
              <li>
                How, when, what and why to test and measure battery parameters?) Understand a
                battery's true condition during discharge
              </li>
              <li>Battery discharge circuit</li>
              <li>Battery changes during discharge</li>
              <li>Battery recharge circuit</li>
              <li>The battery during recharge and what you need to know</li>
              <li>Testing batteries correctly</li>
              <li>Cycle testing electrical circuits</li>
              <li>Static voltage test, O.C.V.</li>
              <li>When battery O.C.V. is 12.66V</li>
              <li>When battery O.C.V. is suddenly down to 10.55V</li>
              <li>When battery O.C.V. is suddenly almost zero volt</li>
              <li>When battery O.C.V. is over 13.00V</li>
              <li>Battery cranking voltage test</li>
              <li>Summary of cranking voltage test</li>
              <li>Battery cranking electron current test</li>
              <li>
                Battery recharge electron current test (something battery manufacturers don't want
                you to know)
              </li>
              <li>Carbon pile battery load test</li>
              <li>Battery bounce-back test</li>
              <li>
                Determine remaining battery life - Overview of Quick 3-step battery test procedure
                (takes 2 minutes or less and you know what you need to know about a battery).
              </li>
            </ul>
          </AccordionItemPanel>
        </AccordionItem>
        <AccordionItem>
          <AccordionItemHeading>
            <AccordionItemButton>Section 5: Cranking Circuit Troubleshooting</AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <p>30 Pages - 18 Illustrations</p>
            <p>
              Can you test the cranking circuit in less than one minute and know if a no/slow crank
              is a battery problem? - a starter motor problem? - or simply a bad connection? Section
              5 shows you how!
            </p>
            <ul>
              <li>Introduction to cranking circuits</li>
              <li>Basic cranking circuit</li>
              <li>Understanding starter motor current</li>
              <li>Overview of troubleshooting cranking circuit problems</li>
              <li>Measure cranking current or starter motor draw</li>
              <li>Measure battery cranking voltage</li>
              <li>3-step cranking circuit testing procedure</li>
              <li>When starter draw is too high</li>
              <li>When starter draw is too low</li>
              <li>Is there a bad connection in the wiring?</li>
              <li>A resistance problem in the starter motor?</li>
              <li>Pin-point a bad connection or cable on voltage side</li>
              <li>3-step QUICK cranking circuit troubleshooting</li>
              <li>Cranking circuit control</li>
              <li>Testing the solenoid control circuit</li>
              <li>Simple cranking circuit</li>
              <li>Failure to crank</li>
              <li>Starter relay circuit</li>
              <li>H-115 Troubleshooting Relay Circuits Trainer</li>
              <li>Troubleshooting starter relay circuit</li>
              <li>Here's a true story.</li>
            </ul>
          </AccordionItemPanel>
        </AccordionItem>
        <AccordionItem>
          <AccordionItemHeading>
            <AccordionItemButton>
              Section 6: Charging Circuit Troubleshooting using only a DMM and Current Clamp
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <p>47 Pages - 30 Illustrations</p>
            <p>
              Can you test the charging system in 60 seconds and know if there is a charging voltage
              problem? Can you tell if the battery burned up the generator you just replaced? Do you
              know how to test a generator when it is controlled by an on-board computer? Can you
              tell if a computer controlling the generator went bad because the computer just failed
              on its own or a generator problem burned up the computer? Section 6 shows you how!
            </p>
            <ul>
              <li>Introduction to generator/charging systems</li>
              <li>Overview of the charging system - Inside a generator</li>
              <li>What a generator does</li>
              <li>
                Interpreting the charging voltage - Three factors that affect the charging voltage
              </li>
              <li>Generator voltage tests</li>
              <li>Generator electron current tests</li>
              <li>The charging voltage test</li>
              <li>When charging voltage is too high or too low</li>
              <li>Understanding generator/battery current test</li>
              <li>Measuring battery recharge electron current</li>
              <li>Factors that determine battery recharge current</li>
              <li>How to measure battery recharge current</li>
              <li>What's good</li>
              <li>What's bad</li>
              <li>Read the DMM correctly</li>
              <li>Determine if a battery is defective when recharging</li>
              <li>
                Try this simple experiment - The conclusion of measuring battery recharge current
              </li>
              <li>Generator ripple voltage test</li>
              <li>Lab scope test of generator output</li>
              <li>
                Overview of testing vehicle charging system - Evaluating charging voltage test
                results
              </li>
              <li>The wrong way to test a generator</li>
              <li>Two major problems with the generator load test</li>
              <li>Computer controlled generator</li>
              <li>How an on-board computer controls the generator</li>
              <li>Here's the problem</li>
              <li>Testing resistance of rotor/field winding</li>
              <li>Hot and cold resistance Evaluating/calculating rotor/field winding condition</li>
              <li>rotor/field windings may be internally grounded</li>
              <li>Introduction to PWM (pulse-width-modulation)</li>
              <li>PWM duty cycle</li>
              <li>PWM rotor/field winding control.</li>
            </ul>
          </AccordionItemPanel>
        </AccordionItem>
        <AccordionItem>
          <AccordionItemHeading>
            <AccordionItemButton>
              Section 7: How to Read Electrical Circuit diagrams
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <p>18 Pages and 9 Schematic Diagrams to study</p>
            <p>
              What does a schematic diagram tell you and what does it not tell you? Section 7 shows
              you how to get the most out of reading schematic diagrams!
            </p>
            <ul>
              <li>Learn how to read a schematic diagram</li>
              <li>What a schematic diagram can do</li>
              <li>or can't do. What is a "Schemation" of a vehicle's primary electrical system</li>
              <li>inventory of a circuit diagram</li>
              <li>Trace the path of electron current</li>
              <li>Measure the voltage around the circuit</li>
              <li>Physically trace the circuit lines</li>
              <li>
                Exercise 7-3: Reading a relay controlled cooling fan circuit diagram
                <ul>
                  <li>How the circuit works</li>
                  <li>Troubleshooting the circuit on paper</li>
                </ul>
              </li>
              <li>
                Exercise 7-4: Reading a relay controlled cranking circuit diagram
                <ul>
                  <li>How the circuit works</li>
                  <li>Troubleshooting the circuit on paper</li>
                </ul>
              </li>
              <li>
                Exercise 7-5: Reading a relay controlled horn circuit diagram
                <ul>
                  <li>How the circuit works</li>
                  <li>Troubleshooting the circuit on paper</li>
                </ul>
              </li>
              <li>
                Exercise 7-6: Reading a rear compartment relay controlled lid release circuit
                diagram
                <ul>
                  <li>How the circuit works</li>
                  <li>Troubleshooting the circuit on paper</li>
                </ul>
              </li>
              <li>
                Exercise 7-7: Reading a relay controlled window defogger circuit diagram
                <ul>
                  <li>How the circuit works</li>
                  <li>Troubleshooting the circuit on paper</li>
                </ul>
              </li>
              <li>
                Exercise 7-8: Reading a relay controlled window/washer pump motor circuit diagram
              </li>
              <li>How the circuit works</li>
              <li>Troubleshooting the circuit on paper</li>
              <li>Conclusion reading schematic/schemation diagrams.</li>
            </ul>
          </AccordionItemPanel>
        </AccordionItem>
      </Accordion>
      <p className="mts">
        Imagine quickly repairing vehicle electrical problems on-the-vehicle in battery, cranking
        and charging circuits using only a DMM and Current Clamp. No need for expensive electrical
        testers dedicated to test these components and circuits.
      </p>
      <p>
        <em>SHORTCUTS</em> provides explanations on how these circuits work and presents innovative
        electrical troubleshooting procedures for batteries, cranking circuits and charging circuits
        that anyone can follow!
      </p>
      <hr />
      <WhatNext />
    </article>
  </Layout>
)

export default Shortcuts
